'use client'

import { type AnimationPlaybackControls, animate, motion, useMotionValue } from 'framer-motion'
import React from 'react'
import { useEffect, useState } from 'react'
import useMeasure from 'react-use-measure'

interface LogoCarouselProps {
  direction?: 'left' | 'right'
  className?: string
  children: React.ReactNode
}

export function LogoCarousel({ direction = 'left', className = '', children }: LogoCarouselProps) {
  const BASE_DURATION = 20
  const MOBILE_DURATION = 10
  const FAST_MULTIPLIER = 0.2
  const [currentDuration, setCurrentDuration] = useState(BASE_DURATION)
  const xTranslation = useMotionValue(0)
  const [ref, { width }] = useMeasure()
  const [containerRef, { width: containerWidth }] = useMeasure()
  const [mustFinish, setMustFinish] = useState(false)
  const [rerender, setRerender] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)

  useEffect(() => {
    const checkMobile = () => {
      setCurrentDuration(window.innerWidth <= 768 ? MOBILE_DURATION : BASE_DURATION)
    }

    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  useEffect(() => {
    if (!width || !containerWidth) return
    let controls: AnimationPlaybackControls | undefined

    if (!isScrolling) {
      const scaledDuration = (currentDuration * containerWidth) / 1000
      const startPosition = direction === 'left' ? 0 : -containerWidth
      const endPosition = direction === 'left' ? -containerWidth : 0

      if (mustFinish) {
        controls = animate(xTranslation, [xTranslation.get(), endPosition], {
          ease: 'linear',
          duration: scaledDuration * (1 - Math.abs(xTranslation.get() - startPosition) / containerWidth),
          onComplete: () => {
            setMustFinish(false)
            setRerender(!rerender)
          },
        })
      } else {
        controls = animate(xTranslation, [startPosition, endPosition], {
          ease: 'linear',
          duration: scaledDuration,
          repeat: Number.POSITIVE_INFINITY,
          repeatType: 'loop',
          repeatDelay: 0,
        })
      }

      return () => controls?.stop()
    }
  }, [width, containerWidth, xTranslation, currentDuration, mustFinish, rerender, isScrolling, direction])

  return (
    <div className="relative h-full">
      <div
        className="absolute inset-y-0 left-0 w-32 z-10  hover:bg-gradient-to-r from-parchement to-transparent"
        onMouseEnter={() => {
          setMustFinish(true)
          setCurrentDuration(BASE_DURATION * FAST_MULTIPLIER)
        }}
        onMouseLeave={() => {
          setMustFinish(true)
          setCurrentDuration(BASE_DURATION)
        }}
      />
      <div
        className="absolute inset-y-0 right-0 w-32 z-10 hover:bg-gradient-to-r from-transparent to-parchement"
        onMouseEnter={() => {
          setMustFinish(true)
          setCurrentDuration(BASE_DURATION * FAST_MULTIPLIER)
        }}
        onMouseLeave={() => {
          setMustFinish(true)
          setCurrentDuration(BASE_DURATION)
        }}
      />

      <div
        className={`w-full h-full overflow-x-auto overflow-y-hidden md:overflow-x-hidden lg:overflow-x-hidden scrollbar-hide ${className}`}
        onTouchStart={() => setIsScrolling(true)}
        onTouchEnd={() => {
          setTimeout(() => setIsScrolling(false), 50)
        }}
        onScroll={() => {
          if (!isScrolling) setIsScrolling(true)
          clearTimeout(window.scrollTimeout as number)
          window.scrollTimeout = setTimeout(() => setIsScrolling(false), 50)
        }}
        style={{
          WebkitOverflowScrolling: 'touch',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
      >
        <motion.div ref={ref} style={{ x: xTranslation }} className="flex h-full">
          <div ref={containerRef} className="flex gap-5 md:gap-10 lg:gap-10 items-center h-full py-2 shrink-0">
            {React.Children.toArray(children).map((child, idx) => (
              <div key={idx} className="shrink-0">
                {child}
              </div>
            ))}
          </div>
          <div className="flex gap-5 md:gap-10 lg:gap-10 items-center h-full py-2 shrink-0">
            {React.Children.toArray(children).map((child, idx) => (
              <div key={`duplicate-${idx}`} className="shrink-0">
                {child}
              </div>
            ))}
          </div>
          <div className="flex gap-5 md:gap-10 lg:gap-10 items-center h-full py-2 shrink-0">
            {React.Children.toArray(children).map((child, idx) => (
              <div key={`triplicate-${idx}`} className="shrink-0">
                {child}
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  )
}
